.add-card-content {
    padding: 0 34px;
    padding-bottom: 50px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    .your-cards-title {
        margin-bottom: 30px;
    }

    .cards-info {
        margin-bottom: 30px;
    }

    .your-payment-cards__cards-data {
        margin-bottom: 35px;
    }

    .cards-data__mastercard,
    .cards-data__visa {
        background-color: #006fcf;
        border-radius: 30px;
        height: 202px;
        min-width: 350px;
        padding: 22px;
    }

    .cards-data__mastercard__card,
    .cards-data__visa__card {
        color: #fff;
        p {
            margin: 0;
        }

        .cards-data__mastercard-logo {
            margin-bottom: 10px;
        }

        .cards-data__card-name {
            margin-bottom: 10px;
        }

        .cards-data__card-number {
            margin-bottom: 10px;
        }

        .edit-card__pencil {
            margin-right: 10px;
        }
    }

    .cards-data__card-number {
        display: flex;
        font-size: 16px;
        justify-content: center;
    }

    p.cards-data__card-number {
        font-weight: bold;
    }

    .cards-data__edit-card {
        align-items: center;
        display: inline-flex;
        left: 30%;
        position: absolute;
        top: 78%;
    }

    .edit-card__edit {
        color: #000;
    }

    .cards-data__verification {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .cards-data__verification-check,
    .cards-data__verification-cross {
        margin-right: 10px;
    }

    .cards-data__verified {
        color: #a5b9cc;
    }

    .cards-data__unverified {
        color: #ff5862;
    }

    .verify-card {
        color: #006fcf;
        display: flex;
        justify-content: center;
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .default-card {
        color: #8dac30;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        img {
            margin-right: 10px;
        }
    }

    .not-default-card {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        a {
            color: #006fcf;
        }
    }

    .cards-content {
        padding-top: 20px;
    }

    .form-container {
        margin-top: 40px;
    }

    .label {
        width: 85%;
    }

    .wallets-title {
        margin-bottom: 20px;
        margin-top: 60px;
    }

    .cards {
        margin-bottom: 20px;
    }
}

@media (max-width: 810px) {
    .add-card-content {
        .label {
            font-size: 14px;
        }
    }
}

@media (max-width: 400px) {
    .add-card-content {
        padding-left: 15px;
        padding-right: 15px;

        .your-payment-cards__cards-data {
            width: 100%;
        }

        .cards-data__mastercard {
            height: auto;
            min-width: 0;
            width: 100%;
        }

        .arrow {
            justify-content: center;

            svg {
                width: 10px;
            }
        }

        .label {
            font-size: 14px;
        }

        .verification-diagram {
            flex-direction: column;
        }

        .fa-arrow-right {
            display: none;
        }

        .fa-arrow-down {
            display: block;
        }

        .step-2 {
            margin-top: 10px;
        }
    }

}
