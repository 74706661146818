.telnum-and-funds {
    display: flex;
    justify-content: space-around;
    margin-bottom: 62px;
    width: 100%;
}

.telnum,
.funds {
    padding: 0 50px;
    padding-top: 50px;
    position: relative;
    width: 50%;
}

.verification-btn-container,
.charge-account-btn-container {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.telnum-title,
.funds-title {
    font-weight: normal;
}

.telnum-num,
.funds-available-balance {
    font-size: 40px;
    line-height: initial;
}

.telnum-title,
.funds-title {
    margin-bottom: 27px;
}

.telnum-num,
.funds-available-balance {
    margin-bottom: 20px;
    text-align: center;
}

.telnum-verified-icon {
    margin-right: 15px;
    svg path {
        fill: #a5b9cc;
    }
}

.telnum-verified {
    color: #a5b9cc;
}

.telnum-verified,
.funds-withdraw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 90px;
}

.funds-withdraw {
    min-height: 90px;
}

.funds-withdraw-icon {
    margin-right: 15px;
    svg path {
        fill: #006fcf;
    }
}

.funds-withdraw-money {
    color: #006fcf;
    text-decoration: underline;
}

.hint {
    background-color: #edeff2;
    display: flex;
    padding: 55px 55px;
}

.hint-content {
    font-size: 13px;
    line-height: 23px;
    margin-right: 66px;
}

.hint-icon {
    svg path {
        fill: #d5d7d9;
        stroke: none;
    }
    svg text {
        stroke-width: 1;
    }
}

.invoices {
    display: flex;
    flex-direction: column;
}

.invoices-and-recent-transactions {
    display: flex;
    justify-content: space-around;
    padding-bottom: 50px;

    .no-invoice-data {
        color: #a5b9cc;
        display: flex;
        font-size: 20px;
        justify-content: center;
        margin-bottom: auto;
    }

    .no-invoice-data-info {
        align-items: center;
        color: #a5b9cc;
        display: flex;
        margin-left: 10px;
    }
}

.invoices-title,
.recent-transactions-title {
    font-weight: normal;
    margin-bottom: 34px;
    padding-top: 44px;
}

.last-transaction,
.before-last-transaction {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.last-transaction {
    margin-bottom: 23px;
}

.transaction-title {
    font-size: 14px;
    max-width: 164px;
    margin: 0 !important;
    padding-right: 20px;
}

.recent-transactions {
    align-items: center;
    display: flex;
}

.transaction-data {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-end;
}

.transaction-amount {
    color: #0091ce;
    font-weight: bold;
}

.transaction-date {
    color: #c3ccd3;
}

.transaction-amount,
.transaction-date {
    margin: 0 !important;
}

.invoices-and-recent-transactions-buttons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 60px;
}

.transaction-icon-bus,
.transaction-icon-tel {
    margin-right: 17px;
}

.skycash-promo {
    padding-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
    img {
        display: block;
        height: auto;
        margin: auto;
        max-width: 100%;
    }
}

.dashboard-invoice-image {
    display: flex;
    justify-content: center;
    margin-bottom: auto;
    margin-top: 20px;
}

.telnum-and-funds,
.invoices-and-recent-transactions {
    flex-wrap: wrap;
}

.old-users-info {
    margin-bottom: 65px;
    margin-top: -65px;
    text-align: center;
}

.verification-btn-container--unverified {
    bottom: auto;
    left: auto;
    margin-bottom: 20px;
    position: relative;
    transform: none;
}

@media(max-width: 600px) {
    .telnum,
    .funds {
        width: 100%;
    }
}

@media(max-width: 950px) {
    .telnum,
    .funds {
        padding: 20px;
        padding-top: 50px;
    }
}