.auto-transfer-edit {
    padding: 34px 34px;

    .title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .nice-select {
        float: none;
        width: 100%;

        .list {
            width: 100%;
        }
    }

    #toAccountId {
        display: block;
    }

    #id_toAccountId {
        width: 100%;
    }

    #id_update_account {
        max-width: 300px;
    }

    .field {
        margin-bottom: 36px;
    }

    .field-pin {
        margin-bottom: 15px;
    }

    .field-info {
        font-size: 14px;
        padding-left: 15px;
    }
}

.general-error {
    color: #ff5862;
    margin-top: 40px;
    text-align: center;
}
