.confirm-invoice-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 75%;
}

.confirm-invoice-dialog {
  display: flex !important;
  flex-direction: column;
  height: 50%;
  padding-top: 34px;
  width: 60% !important;
}

.confirm-title {
  text-align: center;
}

.confirm-result-container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

@media (max-width: 810px) {
  .confirm-invoice-dialog {
    width: 100% !important;
  }
}