.auto-transfer-content {
    padding: 0 34px;
    padding-bottom: 34px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    table {
        margin-bottom: 50px;
        table-layout: fixed;
        width: 100%;
    }

    td {
        word-wrap: break-word;
    }

    .description {
        word-wrap: normal;
        padding-bottom: 10px;
        padding-right: 10px;
        width: 30%;
    }

    .value {
        padding: 0;
        padding-bottom: 10px;
        width: 70%;
    }
}

@media (max-width: 510px) {
    .auto-transfer-content {
        padding-left: 15px;
        padding-right: 15px;

        table {
            font-size: 12px;
        }
    }
}
