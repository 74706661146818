.init_pay {
    padding: 0 34px;

    .title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .init_pay_form {
        max-width: 265px;
    }

    .field {
        display: flex;
        flex-direction: column;
    }

    .phone-format-label {
        font-size: 14px;
    }

    .field-phone {
        margin-bottom: 36px;
    }

    .field-ammount {
        margin-bottom: 36px;
    }

    .field-message {
        margin-bottom: 50px;
    }

    .currency {
        position: relative;
    }

    .currency:after {
        content: "PLN";
        position: absolute;
        right: 0;
        top: 0;
    }

    .contact_message {
        height: 120px;
        resize: none;
    }

    .max-characters {
        font-size: 12px;
        padding-left: 20px;
        padding-top: 10px;
    }
}