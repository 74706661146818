.box-info {
    background-color: #e2f3ff;
    border: 1px solid #a3d9ff;
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
    min-height: 53px;
    width: 90%;
}
.info {
    background: url('../images/info.png') no-repeat center center;
    border-right: 1px solid #a3d9ff;
    width: 54px;
}
.box-info-content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.account-settings {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 34px;
    .info-box {
        margin-top: 30px;
    }
    .info-box__info-content {
        padding-left: 10px;
    }
    .contact-invoice {
        display: flex;
    }
    .invoice-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 30px;
    }
    input {
        padding-bottom: 18px !important;
        padding-left: 14px !important;
    }
    .checkbox-section {
        padding-bottom: 20px;
        padding-left: 14px;
    }
    .checkbox-section__title {
        margin-bottom: 20px;
    }
    .checkbox-input__label {
        padding-left: 14px;
    }

    .contact-data-title,
    .inv-data-title {
        margin-bottom: 20px;
    }

    .invoice__contact-data,
    .invoice__inv-data {
        // width: 35%;
    }

    .invoice__contact-data {
        margin-right: 10px;
    }

    .history-content {
        display: flex;
        flex-direction: column;
    }

    .change-profile__form {
        margin-bottom: 36px;
        margin-top: 34px;
    }

    #profile_change {
        width: 100%;
    }

    .form-control {
        color: #000;
    }

    .account-number-section {
        border-bottom: 2px solid #d5d7d9;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 40px 0;
        width: 90%;
    }

    .account-number__title {
        font-size: 26px;
    }

    .account-number,
    .no-number {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 30px;
    }

    .no-number {
        color: #a5b9cc;
        font-style: italic;
    }

    .acc-btn-section {
        display: flex;
        justify-content: center;
    }

    .account-number-btn {
        margin-top: 30px;
    }

    .field-pin {
        display: flex;
        flex-direction: column;
    }

    .pin-section {
        margin-top: 19px;
    }

    .field-info {
        color: #636364;
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .field-account {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .no-invoice-data {
        display: flex;
    }

    .no-invoice-data-info {
        align-items: center;
        color: #a5b9cc;
        display: flex;
        margin-left: 10px;
    }

    .invoice-company {
        .input-section {
            margin-bottom: 30px;
        }

        .checkbox {
            margin-bottom: 0;
        }
    }
}

.mdl-dialog {
    height: auto;
    width: 400px;

    .mdl-dialog__content {
        display: flex;
        justify-content: center;
    }

    .mdl-dialog__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

.order-invoice-dialogbox {
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-around;
    left: 50%;
    margin: 0 auto;
    padding: 20px;
    position: fixed;
    top: 50%;
    // transform: translateX(-50%) !important;
    // transform: translateY(-50%) !important;
    transform: translate(-50%, -50%) !important;
    width: 400px;
    z-index: 2;
}

.invoice-dialogbox-button {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
}

.nice-select {
    .list {
        height: 200px;
        overflow-y: auto;
    }
}

@media (max-width: 655px) {
    .account-settings {
        .acc-btn-section {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 600px) {
    .account-settings {
        .invoice__contact-data {
            margin-bottom: 40px;
        }

        .inv-data-title {
            margin-bottom: 0;
        }
    }

    .telnum-title,
    .funds-title {
        text-align: center;
    }

    .telnum,
    .funds {
        padding-left: 10px;
        padding-right: 10px;
    }
}
