.change-password {
    padding-left: 34px;

    .change-password__title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .change-pass__form-container {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        padding-bottom: 34px;
    }

    .change-password__form {
        max-width: 265px;
        margin-bottom: 0;
    }

    .field {
        display: flex;
        flex-direction: column;
    }

    .field-info {
        color: #636364;
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .field-pin {
        margin-top: 20px;
    }

    .password-section,
    .pin-section {
        margin-bottom: 0;
    }
}

@media (max-width: 810px) {
    .change-password {
        padding-left: 15px;
        padding-right: 15px;

        .change-password__title {
            text-align: center;
        }

        .change-pass__form-container {
            margin-left: 0;
        }

        .change-password__form {
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
