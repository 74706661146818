.payment-cards {
    padding: 0 34px;

    .payment-cards__title {
        margin-bottom: 40px;
        margin-top: 34px;
    }

    .payment-cards__cards-wallets {
        padding-bottom: 5px;
    }

    .cards-wallets-title {
        margin-bottom: 20px;
    }

    .cards-wallets-content {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .cards-wallets-content__card-link {
        margin-bottom: 40px;
        margin-right: 40px;
    }

    .cards-wallets-content__card {
        border: 1px solid #dddedd;
        display: flex;
        height: 100px;
        justify-content: center;
        width: 186px;
        img {
            align-self: center;
        }
    }

    .your-payment-cards__title {
        margin-bottom: 27px;
    }

    .your-payment-cards__cards-data {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 35px;
    }

    .cards-data__mastercard,
    .cards-data__visa {
        background-color: #006fcf;
        border-radius: 30px;
        height: 202px;
        max-width: 350px;
        padding: 22px;
    }

    .cards-data__mastercard__card,
    .cards-data__visa__card {
        color: #fff;
        p {
            margin: 0;
        }
    }

    .cards-data__card-number {
        display: flex;
        font-size: 16px;
        justify-content: center;
        padding-bottom: 50px;
        padding-top: 36px;
    }

    p.cards-data__card-number {
        font-weight: bold;
    }

    .cards-data__edit-card {
        display: flex;
    }

    .payment-cards--active {
        border: 2px solid #1591ce;
    }

    .cards-data__edit-card {
        justify-content: flex-end;
    }

    .edit-card__pencil {
        padding-right: 10px;
        svg path {
            fill: #fff;;
        }
    }

    .edit-card__edit {
        color: #fff;
        font-size: 16px;
        text-decoration: underline;
    }

    .cards-data__verification {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .cards-data__verification-mastercard,
    .cards-data__verification-visa {
        display: flex;
    }

    .cards-data__verification-check,
    .cards-data__verification-cross {
        margin-right: 8px;
    }

    .cards-data__verification-check {
        svg path {
            fill:#a7bbcd;
        }
    }

    .cards-data__verified {
        color: #a7bbcd;
    }

    .cards-data__verification-cross {
        svg path {
            fill: #ff5c66;
        }
    }

    .cards-data__unverified {
        color: #ff5c66;
    }
}

.card-image-container {
    height: 245px;
    margin: 0 auto;
    position: relative;
    transition: opacity 0.5s ease;
    width: 380px;

    &:before {
        background-image: url("../../images/new-mars/card-plain2.png");
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }
}

.card-image-container--opacity05 {
    &:before {
        opacity: 0.5;
    }
}

.opacity03 {
    opacity: 0.3 !important;
}

.opacity05 {
    opacity: 0.5;
}

.z-index2 {
    z-index: 2;
}

.verify-card-btn-container {
    left: 50%;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
}

.verify-card-btn-container--visible {
    opacity: 1;
    z-index: 1;
}

.cards-data__mastercard-logo {
    display: inline-flex;
    left: 16%;
    position: absolute;
    top: 7%;
}

.cards-data__visa-logo {
    display: inline-flex;
    left: 15%;
    position: absolute;
    top: 15%;
}

.mastercard-text-image {
    display: block;
}

.cards-data__chip {
    display: inline-flex;
    left: 12%;
    position: absolute;
    top: 37%;
}

.cards-data__card-number {
    left: 30%;
    position: absolute;
    top: 55%;
    transition: all 0.1 ease;
}

.cards-data__card-name {
    left: 30%;
    position: absolute;
    top: 65%;
    transition: all 0.1 ease;
}

.edit-card__pencil {
    margin-right: 10px;
}

.edit-card__edit {
    color: #000;
}

.cards-data__edit-card {
    transition: all 0.1s;
}

.card-verified {
    color: #8dac30;
    font-weight: bold;
    left: 30%;
    position: absolute;
    top: 45%;
}

@media (pointer: coarse) {
    .verify-card-btn {
        background-color: inherit;
        color: #0091ce;
        font-size: 14px !important;
        height: auto;
    }

    .verify-card-btn-container {
        left: 53%;
        opacity: 1;
    }

    .cards-data__card-number,
    .cards-data__card-text {
        z-index: 2;
    }
}

@media (max-width: 420px) {
    .card-image-container {
        height: 193px;
        max-width: 300px;
    }

    .cards-data__chip {
        left: 8%;
        transform: scale(0.75);
    }

    .cards-data__visa-logo,
    .cards-data__mastercard-logo {
        transform: scale(0.8);
    }

    .cards-data__card-number,
    .cards-data__card-name,
    .cards-data__edit-card {
        left: 25%;
    }
}

@media (max-width: 310px) {
    .card-image-container {
        height: 142px;
        max-width: 220px;
    }

    .cards-data__chip {
        display: none;
    }

    .cards-data__card-number,
    .cards-data__card-name,
    .cards-data__edit-card {
        font-size: 14px !important;
        left: 10% !important;
    }

    .cards-data__card-number {
        top: 45%;
    }

    .cards-data__card-name {
        display: none;
        top: 58%;
    }

    .edit-card__pencil {
        transform: scale(0.75);
    }

    .cards-data__edit-card {
        top: 75% !important;
    }

    .verify-card-btn {
        font-size: 10px !important;
    }

    .verify-card-btn-container {
        left: 38%;
        top: 67%;
    }

    .card-verified {
        font-size: 10px !important;
        left: 10%;
        top: 58%;
    }
}
