.input-label {
    // padding-left: 14px;
    &:after {
        background-color: #0091ce;
    }
}

.input-section {
    display: block;
    margin-bottom: 16px;
}

.input-field {
    padding-bottom: 18px;
    padding-left: 14px;
}

.input-title {
    margin-bottom: 10px;
}
