.confirm-pay-success {
    padding: 34px;

    .main {
        margin-bottom: 40px;
    }
}

@media (max-width: 810px) {
    .title,
    .main {
        text-align: center;
    }
}
