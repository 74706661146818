.confirm-pay {
    padding: 0 34px;

    .title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .payment-receiver,
    .amount,
    .message {
        margin-left: 20px;
    }

    .field-pin {
        display: flex;
        flex-direction: column;
    }

    #id_confirm_pay {
        max-width: 265px;
    }

    .field {
        margin-bottom: 36px;
    }
}

@media (max-width: 810px) {
    .title {
        text-align: center;
    }
    #id_confirm_pay {
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
