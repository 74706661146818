.invoice-create {
    padding-left: 34px;
    padding-top: 34px;

    .required-sign {
        &:before {
            content: "*";
            font-size: 12px;
            line-height: 16px;
            position: relative;
            top: -5px;
        }
    }

    .mdl-radio.is-checked .mdl-radio__outer-circle {
        border: 2px solid #0091ce;
    }

    .mdl-radio__ripple-container .mdl-ripple,
    .mdl-radio.is-checked .mdl-radio__inner-circle {
        background: #0091ce;
    }

    .invoice-create__form {
        max-width: 265px;
    }

    .radiobutton-section {
        margin-bottom: 40px;
    }

    .field-radio {
        display: flex;
        justify-content: space-around;
    }

    .required-field {
        margin-bottom: 40px;
        margin-left: 20px;
        margin-top: 30px;
    }

    .required-text {
        font-size: 12px;
    }

    .is-default {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-left: 20px;
    }
}

@media (max-width: 810px) {
    .invoice-create {
        padding-left: 15px;
        padding-right: 15px;

        .invoice-create__form {
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
