.paylink-sms-header,
.paylink-pin-header {
  text-align: center;
}

.paylink-sms-form,
.paylink-pin-form {
  margin: 0 auto;
  margin-bottom: 10px;
}

.paylink-success-container {
  .first-title,
  .main {
    margin: 10px;
    text-align: center;
  }
}