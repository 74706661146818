.transaction-details {
    padding: 0 34px;
    padding-bottom: 20px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    .description {
        padding-right: 10px;
        width: 25%;
    }

    .value {
        padding-left: 10px;
        width: 75%;
    }

    table {
        margin-bottom: 40px;
        table-layout: fixed;
        width: 100%;
    }

    td {
        padding-bottom: 10px;
        padding-top: 10px;
        // word-wrap: break-word;
    }
}

@media (max-width: 510px) {
    .transaction-details {
        padding-left: 15px;
        padding-right: 15px;

        table {
            font-size: 12px;
        }

        .value {
            word-wrap: break-word;
        }
    }
}
