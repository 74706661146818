header {
    // background-color: rgb(243, 235, 235);
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.header-logo {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 20px;
    width: 25%;
}

.user-panel {
    justify-content: flex-end;
    padding-right: 15px;
    width: 75%;
}

.userdata {
    display: flex;
    position: relative;
}

.userdata-user-balance {
    color: $user-panel-font-color;
    margin-right: 56px;
}

.userdata-username {
    font-size: 20px;
    margin: 0;
}

.userdata-balance {
    font-size: 16px;
    margin: 0;
    span:first-child {
        font-weight: bold;
    }
}

.userdata-settings {
    align-items: center;
    display: flex;
    justify-content: center;
    svg path {
        fill: none;
        stroke: #4595d0;
    }
    svg circle {
        fill: none;
        stroke: #4595d0;
    }
}

@media (max-width: 810px) {
    #language-select {
        margin-right: 10px;
        transform: scale(1.5);
    }
}
