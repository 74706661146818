.button {
    // border-radius: 25px;
    color: $font-color-buttons;
    cursor: pointer;
    font-size: $font-size-buttons;
    font-weight: bold;

    span {
        font-size: 12px;
    }
}

button {
    font-size: 12px !important;
}

.verify-btn,
.pin-cancel-btn,
.charge-acc-close-btn,
.verify-no-btn,
.verification-btn {
    background-color: #ff5862;
}

.charge-account-btn,
.invoices-add-data-btn,
.show-history-btn,
.transfer-money-btn,
.report-problem-btn,
.login-btn,
.account-number-btn,
.change-pass-btn,
.change-pin-btn,
.add-invoice-profile-btn,
.invoice-update-btn,
.invoice-delete-btn,
.update-data-btn,
.set-pass-btn,
.set-pin-btn,
.add-profile-btn,
.invoice-return-btn,
.pin-next-btn,
.set-pin-return-btn,
.set-pass-return-btn,
.logout-btn,
.charge-account-card-btn,
.payu-btn,
.forgot-pass-btn,
.return-to-login-btn,
.forgot-set-pass-btn,
.passwordchange-next-btn,
.create-account-next-btn,
.pin-verif-next-btn,
.verify-yes-btn,
.login-return-btn,
.verify-account-btn,
.init-pay-send-btn,
.create-invoice-btn,
.invoices-add-data-btn,
.invoices-data-btn,
.charge-account-btn,
.history-search-btn,
.download-csv-btn,
.download-xls-btn,
.parking-download-btn,
.remind-pass-btn,
.register-btn,
.transaction-return-btn,
.verify-card-btn,
.add-card-btn,
.cards-create-acc-btn,
.verify-cvv-btn,
.change-accnum-btn,
.fv-btn,
.invoice-dialogbox-button,
.button--blue {
    background-color: #0091ce;
}

.charge-account__next-btn {
    background-color: #5ca7e2;
}

.button {
    // border-radius: 25px;
    color: $font-color-buttons;
    cursor: pointer;
    font-size: $font-size-buttons;
    font-weight: bold;
}

.invoices-add-data-btn,
.show-history-btn,
.transfer-money-btn,
.charge-account__next-btn,
.report-problem-btn,
.login-btn,
.account-number-btn,
.change-pass-btn,
.change-pin-btn,
.add-invoice-profile-btn,
.invoice-update-btn,
.invoice-delete-btn,
.invoice-update-button,
.invoice-delete-button,
.update-data-btn,
.set-pass-btn,
.set-pin-btn,
.add-profile-btn,
.invoice-return-btn,
.set-pin-return-btn,
.set-pass-return-btn,
.logout-btn,
.charge-account-card-btn,
.payu-btn,
.forgot-pass-btn,
.return-to-login-btn,
.forgot-set-pass-btn,
.passwordchange-next-btn,
.create-account-next-btn,
.pin-verif-next-btn,
.login-return-btn,
.verify-account-btn,
.init-pay-send-btn,
.create-invoice-btn,
.invoices-add-data-btn,
.invoices-data-btn,
.verification-btn,
.charge-account-btn,
.verify-btn,
.remind-pass-btn,
.register-btn,
.transaction-return-btn,
.add-card-btn,
.cards-create-acc-btn,
.verify-cvv-btn,
.change-accnum-btn {
    height: 50px;
    width: 251px;
}

.pin-cancel-btn,
.pin-next-btn,
.verify-no-btn,
.verify-yes-btn,
.history-search-btn,
.download-csv-btn,
.download-xls-btn,
.parking-download-btn,
.invoice-dialogbox-button {
    height: 50px;
    width: 100px;
}

.charge-acc-close-btn,
.verify-card-btn {
    height: 50px;
    width: 170px;
}

.account-number-btn,
.change-pass-btn,
.change-pin-btn,
.add-invoice-profile-btn {
    width: 200px;
}

// chwilowe obejście, żeby link nie wystawał poza button
// bez tego dodawany jest margines, ponieważ container jest rozpychany przez nagłówek
.verify-btn {
    width: 100%;
}

.invoices-add-data-btn,
.transfer-money-btn,
.charge-account__next-btn,
.transaction-history__export-base-btn {
    height: 50px;
    width: 251px;
}

.transaction-history__export-base-btn {
    background-color: #fff;
    border: 2px solid #378eb9;
    color: #378eb9;
    font-size: $font-size-buttons;
}

.transfer-money-btn,
.account-number-btn {
    border: 0;
    display: inline-flex !important;
}

.charge-account__next-btn {
    display: inline-flex !important;
}

.charge-account-btn,
.report-problem-btn,
.login-btn,
.invoice-update-btn,
.invoice-delete-btn,
.update-data-btn,
.set-pass-btn,
.set-pin-btn,
.add-profile-btn,
.pin-cancel-btn,
.pin-next-btn,
.set-pin-return-btn,
.set-pass-return-btn,
.logout-btn,
.charge-account-card-btn,
.charge-acc-close-btn,
.payu-btn,
.forgot-pass-btn,
.return-to-login-btn,
.forgot-set-pass-btn,
.passwordchange-next-btn,
.create-account-next-btn,
.pin-verif-next-btn,
.verify-no-btn,
.verify-yes-btn,
.login-return-btn,
.verify-account-btn,
.init-pay-send-btn,
.create-invoice-btn,
.invoices-add-data-btn,
.show-history-btn,
.invoices-data-btn,
.verification-btn,
.charge-account-btn,
.history-search-btn,
.download-csv-btn,
.download-xls-btn,
.parking-download-btn,
.remind-pass-btn,
.register-btn,
.transaction-return-btn,
.verify-card-btn,
.add-card-btn,
.cards-create-acc-btn,
.invoice-return-btn,
.verify-cvv-btn,
.change-accnum-btn,
.fv-btn,
.invoice-dialogbox-text {
    border: 0;
}
.return-btn {
    border: 1px solid #0091ce;
    border-radius: 3px;
    color: #636364;
    padding: 15px;
    &:hover {
        background-color: #358dcc;
    }
}

.update-data-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.invoice-update-btn-container,
.invoice-delete-btn-container,
.update-data-btn-container,
.set-pass-btn-container,
.set-pin-btn-container,
.add-profile-btn-container,
.invoice-return-btn-container,
.pin-cancel-btn-container,
.pin-next-btn-container,
.set-pin-return-btn-container,
.set-pass-return-btn-container,
.logout-btn-container,
.charge-account-card-btn-container,
.charge-acc-close-btn-container,
.payu-btn-container,
.forgot-pass-btn-container,
.return-to-login-btn-container,
.forgot-set-pass-btn-container,
.passwordchange-next-btn-container,
.create-account-next-btn-container,
.pin-verif-next-btn-container,
.login-return-btn-container,
.verify-account-btn-container,
.init-pay-send-btn-container,
.create-invoice-btn-container,
.invoices-add-data-btn-container,
.show-history-btn-container,
.invoices-data-btn-container,
.verification-btn-container,
.charge-account-btn-container,
.history-search-btn-container,
.download-csv-btn-container,
.download-xls-btn-container,
.parking-download-btn-container,
.remind-pass-btn-container,
.register-btn-container,
.transaction-return-btn-container,
.verify-card-btn-container,
.add-card-btn-container,
.cards-create-acc-btn-container,
.verify-cvv-btn-container,
.fv-btn-container {
    display: flex;
    justify-content: center;
}

.change-pass-btn,
.change-pin-btn,
.invoice-update-btn,
.invoice-delete-btn,
.add-profile-btn-container,
.set-pin-btn-container,
.set-pass-btn-container,
.pin-verif-next-btn,
.forgot-pass-btn-container,
.create-account-next-btn-container,
.remind-pass-btn-container,
.update-data-btn-container {
    margin-bottom: 10px;
}

.set-pin-btn-container,
.set-pass-btn-container,
.logout-btn-container,
.forgot-set-pass-btn-container,
.passwordchange-next-btn-container,
.create-account-next-btn-container,
.create-invoice-btn-container,
.invoices-add-data-btn-container,
.show-history-btn-container,
.invoices-data-btn-container,
.cards-create-acc-btn {
    margin-top: 30px;
}

.verify-card-btn-container,
.charge-account-card-btn-container {
    margin-top: 20px;
}

.payu-btn-container {
    margin-top: 40px;
}

.download-csv-btn-container {
    margin-right: 20px;
}

.history-search-btn-container {
    flex-basis: 100%;
    margin-top: 20px;
}

.change-accnum-btn {
    margin: 0 auto;
}

.pin-next-btn {
    color: #fff;
    font-weight: bold;
}

.fv-btn {
    padding: 4px;
}

.verification-btn-aml {
    background-color: #0091ce;
    height: 40px;
    margin-right: 10px;
    width: 160px;
}

@media (max-width: 280px) {
    button {
        width: 170px !important;
    }
}
