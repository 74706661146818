// .charge-account {
//     padding: 0 34px;
// }
//
// .payment-cards__title {
//     margin-bottom: 5px;
//     margin-top: 39px;
// }
//
// .payment-cards__cards-wallets-title {
//     margin-bottom: 18px;;
//     margin-top: 39px;
// }
//
// .payment-cards__cards-wallets-title {
//     margin-bottom: 18px;
// }
//
//
// .charge-account__cards-content,
// .charge-account__banks-content {
//     display: flex;
//     justify-content: flex-start;
//     flex-wrap: wrap;
// }
//
// .charge-account__cards-content--card,
// .charge_account__banks-content--bank {
//     border: 1px solid #dddedd;
//     display: flex;
//     height: 100px;
//     justify-content: center;
//     // margin-bottom: 40px;
//     // margin-right: 40px;
//     width: 186px;
//     img {
//         align-self: center;
//     }
// }
//
// // a {
// //     .charge-account__cards-content--card:parent {
// //         margin-bottom: 40px;
// //         margin-right: 40px;
// //     }
// // }
//
// .charge-account__cards-content--card-link {
//     margin-bottom: 40px;
//     margin-right: 40px;
// }
//
// .charge-account--active {
//     border: 2px solid #1591ce;
// }
//
// .charge-account__next-btn {
//     margin: 0 auto;
//     margin-bottom: 40px;
// }

.charge-account {
    font-size: 14px;
    padding-bottom: 20px;
    padding-left: 34px;
    padding-right: 34px;

    p {
        line-height: 25px;
        padding: 0 20px;
    }

    h4 {
        margin-top: 40px;
    }

    .charge-account_title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .charge-account__amount {
        display: block;
    }

    .charge-account__currency {
        color: #000;
        position: absolute;
        right: 0;
    }

    .charge-account__amount-label {
        padding-left: 14px;
    }

    .charge-account__amount-title {
        margin-bottom: 10px;
    }

    .charge-account__amount {
        margin-bottom: 20px;
    }

    .charge-account__visa-checkout {
        margin-bottom: 30px;
        padding-left: 20px;
    }

    .charge-account__payment-type {
        margin-top: 40px;
    }

    .charge-account__payment-type-label {
        margin-bottom: 20px;
    }

    .field-ammount {
        width: 30%;
    }

    .amount-field__currency {
        display: flex;
        span {
            align-items: center;
            display: flex;
        }
    }

    .currency {
        font-size: 16px;
        position: relative;
        &:after {
            content: "PLN";
            position: absolute;
            right: 0;
            top: 5px;
        }
    }

    .charge-account__payment-type__radiobuttons {
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        padding: 0 40px;
        padding-bottom: 30px;
        width: 100%;
    }

    .charge-account__radiobutton {
        margin: 0 5px;
    }

    .charge-account__p24,
    .charge-account__payu,
    .charge-account__dotpay,
    .charge-account__ecard {
        align-items: center;
        border: 1px solid #0091ce;
        cursor: pointer;
        display: flex;
        height: 67px;
        justify-content: center;
        padding: 0 15px;
        width: 170px;
    }

    .charge-account__p24-image,
    .charge-account__payu-image,
    .charge-account__dotpay-image,
    .charge-account__ecard-image {
        align-items: center;
        display: flex;
        height: 67px;
        margin-left: 10px;
        width: 83px;
    }

    .charge-account__p24-image {
        background: url('../images/przelewy_24.png') no-repeat center center;
    }

    .charge-account__payu-image {
        background: url('../images/payu_logo.png') no-repeat center center;
    }

    .charge-account__dotpay-image {
        background: url('../images/dotpay.png') no-repeat center center;
    }

    .charge-account__ecard-image {
        background: url('../images/ecard_topup.png') no-repeat center center;
    }

    .charge-account__p24-radio,
    .charge-account__payu-radio,
    .charge-account__dotpay-radio,
    .charge-account__ecard-radio {
        cursor: pointer;
    }

    .charge-account__charge-account-button {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .charge-account_hints {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 40px;
    }

    .charge-account__box-info-p24,
    .charge-account__box-info-payu,
    .charge-account__box-info-dotpay,
    .charge-account__box-info-ecard,
    .charge-account__box-info,
    .box-info {
        // background-color: #e2f3ff;
        background-color: transparent !important;
        // border: 1px solid #a3d9ff;
        border: 2px solid #7fc8e6 !important;
        display: flex;
        margin: 0 auto;
        margin-bottom: 20px;
        min-height: 53px;
        width: 90%;
    }

    .charge-account__box-info-p24,
    .charge-account__box-info-payu,
    .charge-account__box-info-dotpay,
    .charge-account__box-info-ecard {
        display: none;
    }

    .charge-account__box-info-payu {
      display: flex;
    }

    .charge-account__box-info {
        margin-bottom: 50px;
    }

    .charge-account__standard-transfer-content {
        padding: 40px 0;
    }

    .charge-account__transferdata-accountnumber {
        margin-bottom: 30px;
    }

    // .charge-account__info,
    // .info {
    //     background: url('../images/info.png') no-repeat center center;
    //     border-right: 1px solid #a3d9ff;
    //     width: 54px;
    // }

    // .charge-account__info-content,
    // .box-info-content {
    //     align-items: center;
    //     display: flex;
    //     justify-content: center;
    //     width: 100%;
    // }

    .charge-account__standard-transfer-content-main {
        margin-bottom: 40px;
    }

    .charge-account__standard-transfer-content-title {
        margin-bottom: 20px;
    }

    .charge-account__visamc-title {
        margin-bottom: 20px;
    }

    .charge-account__transferdata {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    .charge-account__transferdata-account,
    .charge-account__transferdata-data {
        display: flex;
        justify-content: center;
    }

    // .charge-account__transferdata-name,
    // .charge-account__transferdata-accountnumber,
    // .charge-account__transferdata-name,
    // .charge-account__transferdata-address {
    //     border: 1px solid #e5e5e5;
    //     padding: 20px;
    // }
    //
    // .charge-account__transferdata-name,
    // .charge-account__transferdata-name {
    //     width: 40%;
    // }
    //
    // .charge-account__transferdata-accountnumber,
    // .charge-account__transferdata-address {
    //     width: 50%;
    // }

    // .charge-account__info-content {
    //     padding-left: 10px;
    // }

    .charge-account__visamc {
        border-top: 1px solid #e5e5e5;
    }

    .charge-account__visamc-main {
        margin-bottom: 15px;
        width: 95%;
    }

    .charge-account__visamc-cards {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 40px;
    }

    .charge-account__visamc-link {
        color: #008cd2;
        &:hover {
            cursor: pointer;
            span {
              &:before,
              &:after {
                opacity: 1;
                width: 50%;
              }
            }
        }
    }

    .charge-account__zabka-main {
        margin-bottom: 40px;
    }

    .charge-account__zabka-quick-guide {
        display: flex;
        justify-content: space-around;
        margin-bottom: 40px;
    }

    .charge-account__zabka-quick-guide-text {
        margin-bottom: 10px;
    }

    .charge-account__zabka-quick-guide-image {
        margin: 0 auto;
    }

    .charge-account__zabka-quick-guide-text,
    .charge-account__zabka-quick-guide-image {
        display: block;
        text-align: center;
    }

    .charge-account__download {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        padding-left: 20px;
    }

    .charge-account__download-title {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .charge-account__download-android,
    .charge-account__download-iphone,
    .charge-account__download-windows {
        color: #595959;
        margin-right: 10px;
        width: 180px;
    }

    .charge-account__download__icon-holder-1,
    .charge-account__download__icon-holder-2,
    .charge-account__download__icon-holder-3 {
        align-items: center;
        border: 1px solid #d8d8d8;
        display: inline-flex;
        justify-content: center;
        font-size: 19px;
        height: 56px;
        min-width: 130px;
        padding: 10px;
        width: 100%;
        &:hover {
            cursor: pointer;
            span {
              &:before,
              &:after {
                opacity: 1;
                width: 50%;
              }
            }
        }
    }
    .charge-account__download__icon-holder-1 {
        .icon-normal {
            background: url('../images/download_app_1.png') no-repeat;
        }
    }
    .charge-account__download__icon-holder-2 {
        .icon-normal {
            background: url('../images/download_app_2.png') no-repeat;
        }
    }
    .charge-account__download__icon-holder-3 {
        .icon-normal {
            background: url('../images/download_app_3.png') no-repeat;
        }
    }
    .icon {
        height: 27px;
        margin-right: 10px;
        width: 21px;
    }
    .icon-normal {
        height: 27px;
        width: 21px;
    }
    .h1 {
        font-size: 14px;
    }
    .line,
    .charge-account__visamc-link__line {
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        transition: all 0.2s ease-in-out;
        &:before,
        &:after {
            background-color: #595959;
            bottom: -5px;
            content: "";
            height: 1px;
            margin: 1px 0 0;
            opacity: 0;
            position: absolute;
            transition: all 0.2s ease-in-out;
            transition-duration: 0.2s;
            width: 0px;
        }
        &:before {
            left: calc(50%);
        }
        &:after {
            right: calc(50%);
        }
    }
    .charge-account__visamc-link__line {
        &:before,
        &:after {
            background-color: #008cd2;
        }
    }
}

.payu-error {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.payu-error,
.payu-success {
  padding: 32px;
}

.payu-success__main-container,
.payu-error__main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payu-success__main,
.payu-error__main {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.charge-account__form {
  margin-bottom: 30px;
}

@media (max-width: 810px) {
    .charge-account {
        .charge-account__payment-type__radiobuttons {
            flex-wrap: wrap;
            padding: 0;
        }

        .charge-account__radiobutton {
            margin-bottom: 5px;
        }

        p {
            width: 100% !important;
        }

        .field-ammount {
            width: 200px;
        }
    }
}

@media (max-width: 600px) {
    .charge-account {
        .charge-account__download {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 0;
        }

        .charge-account__download-app {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 500px) {
    .charge-account {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 300px) {
    .charge-account {
        .charge-account__visa-checkout {
            padding: 0;
        }

        img {
            width: 100%;
        }

        .field-ammount {
            width: 90%;
        }
    }
}
