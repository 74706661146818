.form-inputs-container-second {
    margin: 0 auto;
    width: 250px;

    .field {
        margin-bottom: 20px;
    }

    .main {
        color: #636364;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .terms-of-use {
        color: #008cd2;
    }

    .field-pin {
        margin-bottom: 40px;
    }
}

#id_register_form2 {
    .title {
        margin-bottom: 20px;
    }

    .main {
        margin-bottom: 20px;
    }
}

@media (max-width: 350px) {
    .create-account-first-content {
        .form-inputs-container-second {
            width: 100%;
        }
    }
}
