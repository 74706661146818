.main-content-passwordchange {
    display: flex;
    justify-content: center;

    .main-content {
        width: 50%;
    }

    .title {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .form-container {
        padding: 30px;
    }

    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    input {
        padding-left: 14px;
    }

    .field-info {
        color: #636364;
        font-size: 14px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    #id_passwordchange_onetimecode {
        max-width: 300px;
    }

    .form-password {
        display: flex;
        justify-content: center;
    }

    label {
        line-height: 1.25;
    }
}

@media (max-width: 750px) {
    .main-content-passwordchange {
        .main-content {
            width: 80%;
        }
    }
}

@media (max-width: 380px) {
    .forgot-set-pass-btn {
        width: 200px;
    }
}

@media (max-width: 350px) {
    #id_passwordchange_onetimecode {
        max-width: 100%;
    }
}