.main-content-forgot-password {
    align-items: center;
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-top: auto;
    width: 50%;

    .form-create-phone {
        padding: 30px;
    }

    .title {
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .phone-label {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .field-phone {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    #id_phone {
        width: 200px;
    }

    .input-container {
        display: flex;
        justify-content: center;
    }

    .forgot-pass-info {
        color: #636364;
        &:nth-child(2) {
            margin-bottom: 20px;
        }
        &:nth-child(3) {
            margin-bottom: 20px;
        }
    }

    .bottom {
        margin-top: 40px;
    }

    .form-error {
        color: #ff5862;
        font-size: 14px;
    }

    .remind-pass-title {
        margin-bottom: 15px;
        text-align: center;
    }
}

@media (max-width: 650px) {
    .main-content-forgot-password {
        width: 100%;
    }
}
