.logout-section {
    .title {
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .logout-title {
        margin-bottom: 15px;
        text-align: center;
    }

    .logout-main {
        text-align: center;
    }
}

@media (max-width: 650px) {
    .logout-section {
        .main-content {
            width: 85%;
        }
    }
}

@media (max-width: 320px) {
    .logout-section {
        .logout-btn {
            width: 200px;
        }
    }
}
