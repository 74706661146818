.verification {
    padding-left: 34px;
    padding-right: 34px;

    .title {
        margin-bottom: 40px;
        padding-top: 45px;
    }

    .main {
        margin-bottom: 30px;
    }

    .form-verification {
        margin-top: 60px;
    }

    .field-pin {
        width: 20%;
    }

    .pin {
        text-align: center;
    }

    .field-pin-container {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    // .step-1 {
    //     margin-left: 100px;
    // }

    // .arrow {
    //     padding-left: 50px;
    // }

    .form-error {
        color: #ff5862;
        font-size: 14px;
    }

    .step-2 {
        .label {
            max-width: 150px;
            text-align: center;
        }
    }
}

@media (max-width: 550px) {
    .verification {
        .fa-arrow-down {
            display: block;
        }

        .fa-arrow-right {
            display: none;
        }

        .verification-diagram {
            flex-direction: column;
        }

        .arrow {
            justify-content: center;
        }
    }
}
