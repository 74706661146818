@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&subset=latin-ext');

* {
    box-sizing: border-box;
    font-family: 'Lato', 'Arial', 'Helvetica', sans !important;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
}

// html,
// body {
//     height: 100%;
// }

// body::-webkit-scrollbar {
//     width: 1em;
// }

// body::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// }

// body::-webkit-scrollbar-thumb {
//     background-color: darkgrey;
//     outline: 1px solid slategrey;
// }

.main-container {
    // height: 100vh;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
}

.header-container,
.footer-container {
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
}

.footer-container {
    justify-content: center;
}

.centered-xy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.centered-x {
    display: flex;
    justify-content: center;
}

.centered-y {
    align-items: center;
    display: flex;
}

.bold {
    font-weight: bold;
}

a {
    text-decoration: none !important;
    &:active {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
}

p {
    font-size: 16px;
    margin: 0;
}

.nav-and-content {
    display: flex;
    width: 100%;
}

.main-content {
    // background: linear-gradient(#ECF1F6, #FBFBFB 3%, #FBFBFB 50%, #FBFBFB 98%, #EDF0F5);
    // -webkit-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.75);
    // -moz-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.75);
    // box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.75);
    // box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.14);
    display: inline-block;
    width: 74%;

    &::-webkit-scrollbar {
        width: 1em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

::placeholder {
    color: rgba(0,0,0,.26);
}

.errorlist {
    color: #ff5862;
    list-style: none;
    margin-top: 10px;
}

.hamburger-container {
    display: none;
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #368dcc;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.navigation-list-mobile {
    display: none;
}

.fa-arrow-down {
    display: none;
}

#language-select,
.user-settings {
    svg {
        transform: scale(1.7);
        path {
            color: #4595d0;
        }
    }
}

@media (max-width: 810px) {
    .main-content {
        width: 100%;
    }

    .footer-25,
    .userdata-user-balance,
    nav,
    .userdata-settings,
    #demo-menu-lower-right {
        display: none;
    }

    .hamburger-container {
        display: inline-block;
    }

    .hint-content {
        margin-right: 10px;
    }

    .navigation-list-mobile {
        background-color: #fafafa;
        box-shadow: 0px 9px 32px -5px rgba(0,0,0,0.75);
        // display: block;
        position: absolute;
        transform: translateY(75px);
        width: 100%;
        z-index: 1000;
    }

    .list-element-icon-logout {
        padding-left: 5px;
    }

    .hint {
        padding: 20px;
    }

    .telnum-num,
    .funds-available-balance {
        font-size: 22px;
        text-align: center;
    }
}

// @media (max-width: 650px) {
//     .skycash-promo {
//         img {
//             max-height: 220px;
//         }
//     }
// }
//
// @media (max-width: 450px) {
//     .skycash-promo {
//         img {
//             max-height: 190px;
//         }
//     }
// }

@media (max-width: 810px) {
    .main-content,
    .content {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .mdl-dialog {
        width: 90%;

        .mdl-dialog__actions {
            padding: 20px 8px;
        }
    }
}

@media (max-width: 280px) {
    .main-content {
        padding: 0 5px;
    }

    .mdl-dialog {
        a:first-child {
            margin-bottom: 10px;
        }
    }
}
