.main-content-login {
    // align-items: center;
    display: flex;
    justify-content: center;
    margin-top: auto;
    .main-content {
        position: relative;
        width: 50%;
//         &:before {
//     content: "";
//     background-color: #000;
//     opacity: 0.7;
//     top: 0;
//     left: 0;
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     z-index: 0;
// }
    }
    .title {
        display: flex;
        justify-content: center;
    }
    .form-create-phone {
        display: flex;
        justify-content: center;
    }
    .form-container {
        padding: 30px;
    }
    .phone-label,
    .password-label {
        padding-left: 0;
    }
    .captcha {
        margin-top: 20px;
    }
    .phone-label,
    .password-label,
    .captcha-label {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .password-label,
    .captcha-label {
        margin-top: 20px;
    }
    .field-phone,
    .field-password,
    .field-captcha {
        // align-items: center;
        display: flex;
        flex-direction: column;
    }
    input {
        // background-color: #e2f6ff !important;
        width: 100%;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ecf6fb inset;
    }
    .btn-container {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .quick-link {
        display: flex;
        justify-content: center;
    }
    .helpers {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .remind-pass {
        margin-bottom: 10px;
    }
    .remind-pass,
    .register {
        color: #008cd2;
        &:hover {
            cursor: pointer;
            span {
              &:before,
              &:after {
                opacity: 1;
                width: 50%;
              }
            }
        }
    }
    .remind-pass__link,
    .register__link {
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        transition: all 0.2s ease-in-out;
        &:before,
        &:after {
            background-color: #595959;
            bottom: -5px;
            content: "";
            height: 1px;
            margin: 1px 0 0;
            opacity: 0;
            position: absolute;
            transition: all 0.2s ease-in-out;
            transition-duration: 0.2s;
            width: 0px;
        }
        &:before {
            left: calc(50%);
        }
        &:after {
            right: calc(50%);
        }
    }
    .remind-pass__link,
    .register__link {
        &:before,
        &:after {
            background-color: #008cd2;
        }
    }
    .logout-form {
        padding-top: 0;
    }
    .logout-title {
        display: flex;
        justify-content: center;
    }
    .logout-main {
        // margin: 0 auto;
        // width: 80%;
    }
    .form-error {
        color: #ff5862;
        display: flex;
        font-size: 14px;
        justify-content: center;
        margin-top: 20px;
    }
}
.logout-header {
    display: flex;
    justify-content: space-between;
}
.login-section {
    .title {
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .field-phone,
    .field-password {
        input {
            text-align: center;
        }
    }
}

.vertical-login {
    margin-top: auto;
}

.login-form {
    max-width: 265px;
}

@media (max-width: 650px) {
    .login-section {
        .main-content {
            width: 100%;
        }

        .title {
            text-align: center;
        }
    }
}
