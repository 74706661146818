.transaction-history {
    padding-bottom: 50px;
    padding-left: 34px;
    padding-right: 34px;
}

.transaction-history-title {
    margin-bottom: 53px;
    padding-top: 45px;
}

.transaction-history {
    #DataTables_Table_0_wrapper {
        margin-top: 30px;
    }
    table.dataTable.no-footer {
        border-bottom: 2px solid #e8eaee;
        margin-bottom: 20px;
        padding-top: 20px;
    }

    table.dataTable thead th {
        border: 0;
        border-bottom: 2px solid #e8eaee;
        border-top: 2px solid #e8eaee;
        font-size: 14px;
        font-weight: normal;
        padding: 22px;
        text-align: left;
    }

    table.dataTable tbody tr td {
        font-size: 12px;
        padding: 12px 22px;
        text-align: left;
    }

    table.dataTable thead th:not(:first-child):not(:last-child),
    table.dataTable thead th:last-child {
        border-left: 2px solid #e8eaee;
    }

    table.dataTable tbody tr.odd {
        background-color: #f9f9f9;
    }

    table.dataTable tbody tr.odd td {
        border-bottom: 1px solid #e8eaee;
    }

    table.dataTable tbody tr.even {
        background-color: #fff;
    }

    table.dataTable tbody tr.even td {
        border-bottom: 2px solid #e8eaee;
    }
    td {
        a {
            color: #0091ce;
        }
    }
    #DataTables_Table_0_filter {
        label {
            text-align: left;
        }
    }
    .dataTables_length {
        select {
            margin: 0 15px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
            text-align: center !important;
        }
        label {
            align-items: center;
            display: flex;
            margin-bottom: 20px;
        }
    }
    .calendar {
        display: inline-block;
        width: 16px;
        height: 33px;
        margin-left: 6px;
        vertical-align: middle;
        background: url('../images/calendar.png') center center no-repeat;
        line-height: 33px
    }
    #trans_history_form {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        #id_date_from_0,
        #id_date_from_1,
        #id_date_to_0,
        #id_date_to_1,
        #id_months {
            text-align: center;
            width: 40px;
        }
        #id_date_from_2,
        #id_date_to_2 {
            text-align: center;
            width: 60px;
        }

        .field-radio {
            align-items: center;
            display: flex;
        }

        .field-calendar {
            align-items: center;
            display: flex;

            label {
                margin-right: 5px;
            }
        }

        label {
            margin: 0;
            margin-bottom: 15px;
        }

        input {
            margin-bottom: 15px;
        }

        .subgroup-last {
            display: flex;
        }

        #id_months {
            text-align: center;
        }

        .field-last {
            align-items: center;
            display: flex;
        }

        .input-default {
            align-items: center;
            display: flex;
        }
    }
    .download-history {
        margin-top: 40px;
    }

    .download {
        margin-bottom: 30px;
    }

    .download-buttons {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        padding-left: 20px;
    }

    .parking-raport__title {
        margin-bottom: 20px;
    }

    .parking-raport__form-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        input {
            margin-bottom: 15px;
            margin-right: 10px;
            width: 100px;
        }

        label {
            margin-bottom: 15px;
            margin-right: 5px;
        }

        .radiobutton-pdf {
            margin-right: 10px;
        }

        fieldset {
            margin-left: 20px;
        }
    }

    .parking-date-from,
    .parking-date-to {
        margin-left: 20px;
    }

    .dataTables_filter {
        display: flex;
    }

    .transaction-history__table {
        td {
            padding: 8px 8px !important;
        }
    }

    .form-history {
        .date-to-container {
            display: flex;
            flex-direction: row;
        }
    }
}

.transaction-history__table {
    margin-top: 20px;
}

.transaction-history__table-thead__row {
    background-color: #fff;
}

.transaction-history__amount {
    min-width: 100px;
}

.transaction-history__table-container {
    overflow-x: auto;
}

.dataTables_wrapper {
    padding-bottom: 30px !important;
}

// OVERWRITE DATATABLES.MIN.CSS
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: none !important;
    border: none !important;
    background-color: #0091ce !important;
    color: #fff !important;
    font-weight: bold;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: none !important;
    border: none !important;
    background-color: #66bde1 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background: none !important;
}

@media (max-width: 1060px) {
    #trans_history_form {
        justify-content: flex-start !important;
    }

    .subgroup-last {
        flex-basis: 100%;
    }

    .field-date-to {
        margin-left: 20px;
    }
}

@media (max-width: 500px) {
    .registration-number {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    .parking-date-from,
    .parking-date-to {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-left: 0 !important;
    }

    .download-buttons {
        justify-content: space-around;
        padding-left: 0 !important;
    }

    .field-date-to {
        margin-left: 25px;
    }

    .download-csv-btn-container {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .transaction-history {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 300px) {
    .dataTables_paginate {
        display: flex;
        flex-direction: column;
    }
}
