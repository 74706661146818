.card-verification {
    form {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .field-postal-code,
    .field-ammount {
        margin-bottom: 50px;
    }

    .input-default {
        position: relative;
        &:after {
            content: "PLN";
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    label {
        margin-bottom: 20px;
    }
}
