footer {
    align-content: center;
    // background-color: rgb(243, 235, 235);
    display: flex;
    justify-content: center;
    a {
        align-items: center;
        display: flex;
    }
    flex-shrink: 0;
}

.footer-logo {
    display: inline-flex;
    padding: 20px 0;
}

.footer-media {
    align-content: center;
    display: inline-flex;
    padding-left: 50px;
    .facebook,
    .twitter,
    .youtube {
        align-items: center;
        display: flex;
        padding: 0 5px;
        svg {
            &:hover {
                fill: #356bcc;
            }
        }
    }
}

.footer-25 {
    width: 25%;
}

.footer-75 {
    display: flex;
    justify-content: center;
    width: 75%;
}
