nav {
    // background-color: rgb(243, 235, 235);
    display: inline-block;
    width: 25%;
}

.navigation-list {
    list-style-type: none;
}

.nav-element__admin-panel,
.nav-element__money-transfer,
.nav-element__charge-account,
.nav-element__transaction-history,
.nav-element__cards,
.nav-element__account-settings,
.nav-element__report-problem {
    &:hover {
        background-color: rgba(#0091ce, 0.05);
    }
}

.nav-element {
    align-items: center;
    display: flex;
    padding: 20px 0;
    padding-left: 10px;
}

.list-element-icon {
    display: inline-block;
    margin-right: 10px;
    min-height: 24px;
    min-width: 24px;
    svg path {
        fill: $active-list-element-color;
    }
}

.list-element-text {
    color: $list-element-font-color;
    display: inline-block;
    font-size: 13px;
}

.bok-number-section {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 80px;
}

.bok-title {
    color: #3a4352;
    font-size: 10px;
    font-weight: bold;
}

.bok-number1 {
    margin-top: 15px;
}

.bok-number1,
.bok-number2 {
    color: #636364;
    font-size: 19px;
}

.list-element--active {
    color: $active-list-element-color;
}

.nav-element-active {
    background-color: $nav-element-active-background-color;
}
