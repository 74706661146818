.transfer-money-out {
    height: 100%;
    padding: 0 34px;
    padding-bottom: 50px;

    .title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .info {
        background: none;
        border: 0;
        width: auto;
    }

    #id_transfer_money_out {
        max-width: 265px;
    }

    .accnum-info {
        margin-bottom: 20px;
    }

    .field-ammount {
        margin-top: 40px;
    }

    .input-default {
        position: relative;
    }

    .input-amount {
        position: relative;

        &:after {
            content: "PLN";
            position: absolute;
            right: 0;
            bottom: 2px;
        }
    }

    .field-pin {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .accnum-info {
        word-wrap: break-word;
    }
}

@media (max-width: 810px) {
    .init_pay,
    .transfer-money-out-content {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .title {
            text-align: center;
        }

        .init_pay_form,
        #id_transfer_money_out {
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
