.charge-account-payu {
    *, *:before, *:after {
        box-sizing: border-box;
    }
    // padding: 0 30px;
    .payment {
        display: flex;
    }
    .all-table-pay {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
    }
    .payment-type {
        border: 1px dotted #c9c9c9;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        flex-direction: column;
        height: 120px;
        justify-content: center;
        margin: 5px;
        // padding: 15px;
        // min-width: 33%;
        min-width: 150px;
        position: relative;
    }
    .payment-type-box {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 10px;
    }
    .payment-type-image {
    }
    img {
        width: 100px;
    }
    .radiobutton {
        cursor: pointer;;
    }
    .radiobutton,
    .payment-description {
        bottom: 0;
    }
    .payment-description {
      text-align: center;
    }
    .payment-type__select {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 5px;
        // margin-top: auto;
        // padding-top: 10px;
        position: absolute;
        width: 100%;

        .radiobutton {
          visibility: hidden;
        }
    }
    .payu-button {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}
