.hash {
    padding: 0 34px;
    padding-bottom: 50px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    .main {
        margin-bottom: 20px;
    }

    .field {
        margin-bottom: 20px;
    }

    .field-info-key,
    .field-info-pin {
        font-size: 12px;
    }

    .form {
        margin-top: 40px;
        max-width: 350px;
    }
}
