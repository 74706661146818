.callbackurl {
    padding: 0 34px;
    padding-bottom: 50px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    .main {
        margin-bottom: 20px;
    }

    .field {
        margin-bottom: 20px;
    }

    .form-callback {
        margin-top: 40px;
        max-width: 350px;
    }

    .field-info-pin,
    .field-info-callbackurl {
        font-size: 12px;
    }

    .field-info-callbackurl {
        word-wrap: break-word;
    }
}

.info-box {
    margin-top: 30px;
}
