.change-pin {
    padding-left: 34px;

    .change-pin__title {
        margin-bottom: 40px;
        padding-top: 34px;
    }

    .change-pin__form-container {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: 40px;
    }

    .change-pin__form {
        max-width: 265px;
    }

    .field {
        display: flex;
        flex-direction: column;
    }

    .field-info {
        color: #636364;
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .field-pin {
        margin-top: 20px;
    }

    .password-section,
    .pin-section {
        margin-bottom: 0;
    }
}

@media (max-width: 810px) {
    .change-pin {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .title {
            text-align: center;
        }

        .change-pin__form-container {
            margin: 0;
        }

        .change-pin__form {
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
}
