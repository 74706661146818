.verification-code {
    padding-left: 34px;
    padding-right: 34px;

    .first-title{
        margin-bottom: 40px;
        padding-top: 45px;
    }

    .verification-diagram {
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        margin: 0;
        margin-bottom: 30px;
        margin-top: 30px;
        padding: 50px 80px;
    }

    .field-group-info__title {
        margin-bottom: 30px;
    }

    .main-info {
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .main-info-sorbnet {
        color: #636364;
        padding: 0 20px;
    }

    .field-group-info {
        margin-bottom: 50px;
    }

    .bank-buttons {
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
        padding: 40px 0;
    }

    .button_bank {
        align-items: center;
        border: 1px solid #0091ce;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        margin-right: 5px;
        min-height: 90px;
        min-width: 150px;

        img {
            max-height: 70px;
            max-width: 130px;
        }
    }

    .if-no-bank-info {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 30px;
    }

    .thankyou {
        padding-bottom: 30px;
    }

    .thankyou__title,
    .thankyou__main {
        display: flex;
        justify-content: center;
    }

    .thankyou__title {
        margin-bottom: 20px;
    }
}

@media (max-width: 810px) {
    .verification,
    .verification-code {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 550px) {
    .verification-code {
        .fa-arrow-down {
            display: block;
        }

        .fa-arrow-right {
            display: none;
        }

        .verification-diagram {
            flex-direction: column;
        }

        .arrow {
            justify-content: center;
        }
    }
}
