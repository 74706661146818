.passwordchange-success {
    .title {
        margin-top: 20px;
    }

    .passwordchange-success-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
}


@media (max-width: 810px) {
    body {
        display: flex;
    }

    section {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
    }
}

@media (max-width: 650px) {
    .passwordchange-success {
        width: 80%;
    }
}

@media (max-width: 350px) {
    .passwordchange-next-btn {
        .passwordchange-next-btn {
            width: 200px;
        }
    }
}