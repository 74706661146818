.change-password-success {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 34px 15px;

    .pass-changed-info {
        margin-bottom: 30px;
    }
}
