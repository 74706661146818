.info-box {
    align-items: center;
    // background-color: #e2f3ff;
    background-color: transparent !important;
    // border: 2px solid #a3d9ff;
    border: 2px solid #7fc8e6 !important;
    border-radius: 4px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: 53px;
    width: 90%;
}

.info-box__info-icon {
    // background: url('../images/info.png') no-repeat center center;
    // border-right: 2px solid #a3d9ff;
    border-right: 2px solid #7fc8e6 !important;
    // width: 54px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 8%;
    svg {
        path {
            color: #0091ce;
        }
        transform: scale(1.25);
    }
}

.info-box__info-content {
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: 13px;
    justify-content: center;
    padding: 15px;
    width: 100%;
}

.general-error {
        background-color: #ffdddf;
        border: 1px solid #ff5862;
        margin-left: 34px;
        margin-right: 34px;

    .info-box__info-icon {
        border-right: 1px solid #ff5862;
        svg {
            path {
                color: #ff5862;
            }
        }
    }
}
