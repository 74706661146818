.change-pin-success {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 34px;
    padding-bottom: 34px;

    .title {
        margin-bottom: 50px;
        padding-top: 45px;
    }

    .change-pin-confirmation {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;

        .main {
            margin-bottom: 50px;
        }
    }
}
