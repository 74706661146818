.verification-diagram {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 60px;
}

.step {
    align-items: center;
    background-color: #bbbdc0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 85px;
    margin-bottom: 10px;
    width: 85px;
}

.active {
    background-color: #0091ce;
}

.step-1,
.step-2,
.step-3 {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.step-number {
    color: #fff;
    font-size: 32px;
}

.arrow {
    align-items: center;
    display: flex;
    height: 85px;
    svg {
        path {
            color: #636364;
        }
        transform: scale(3);
    }
}
