.create-account-first-content {
    display: flex;
    justify-content: center;

    .title {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .form-create {
        padding: 30px;
    }

    .field-phone,
    .field-number {
        margin-bottom: 20px;
    }

    .field-number {
        display: flex;
        flex-direction: column;
    }

    .captcha {
        width: 130px;
    }

    .captcha-container {
        display: flex;
        justify-content: center;
    }

    .form-inputs-container {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .image-container {
        text-align: center;
        width: 100%;
    }

    img {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .form-error {
        color: #ff5862;
        font-size: 14px;
        margin-bottom: 20px;
    }
}

@media (max-width: 550px) {
    .create-account-first-content {
        .fa-arrow-down {
            display: block;
        }

        .fa-arrow-right {
            display: none;
        }

        .verification-diagram {
            flex-direction: column;
        }

        .arrow {
            justify-content: center;
        }
    }
}
