.report-problem {
    padding-bottom: 60px;
    padding-left: 34px;
    padding-right: 34px;

    .mdl-textfield__label {
        &:after {
            bottom: 10px;
        }
    }

    .bok-number-section {
        padding: 0;
        margin-bottom: 20px;
    }

    .report-problem-info {
        margin-bottom: 20px;
    }
}

.report-problem__title {
    padding-bottom: 44px;
    padding-left: 35px;
    padding-top: 40px;
}

.report-problem__hint {
    background: linear-gradient(to right, #edeff2 , #f2f3f5);
    display: flex;
    max-width: 400px;
    min-height: 118px;
    margin-bottom: 62px;
    padding: 0 20px;
    margin-bottom: 0;
}

.report-problem__hint-content {
    margin: 0;
}

.hint-content__faq {
    font-weight: bold;
}

.report-problem__form,
.report-problem__form + a {
    padding-left: 36px;
}

.problem-type__icon {
    margin-top: -42px !important;
}

.report-problem__label {
    display: block;
    margin-bottom: 6px;
}

.report-problem__problem-description {
    margin-bottom: 26px;
}

.ui-datepicker-header {
    background-color: #0091ce;
}

.ui-state-highlight {
    background-color: #0091ce !important;
    border: 1px solid #0091ce !important;
    color: #000 !important;
    font-weight: bold !important;
}

.ui-state-active {
    background-color: #356bcc !important;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer !important;
}

.report-problem__label[for="tran-num"],
.report-problem__label[for="tran-date"],
.report-problem__label[for="last-card-num"],
.report-problem__label[for="app-version"],
.report-problem__label[for="device-model"],
.report-problem__label[for="other-problem"],
.report-problem__label[for="problem-message"], {
    display: none;
}

.report-problem__transaction-number,
.report-problem__transaction-date,
.report-problem__last-card-num,
.report-problem__app-version,
.report-problem__device-model,
.report-problem__other-problem,
.report-problem__problem-description {
    display: none;
}

#problem-message {
    resize: none;
}

#widget_button {
    height: 0;
}

@media (max-width: 810px) {
    .report-problem {
        .report-problem__form {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}

@media (max-width: 500px) {
    .report-problem {
        .report-problem__title {
            padding-left: 0;
            text-align: center;
        }

        .report-problem__form {
            margin: 0 auto;
            max-width: 300px;
            padding-left: 0;
        }

        .charge-account__charge-account-button {
            text-align: center;
        }
    }
}
